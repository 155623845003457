import { GiftOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Divider, Image, Layout, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Camioneta from "../../assets/img/kisspng-2018.png.png";
import Logo1 from "../../assets/img/image 73.png";
import Logo2 from "../../assets/img/image 74.png";
import Logo3 from "../../assets/img/image 75.png";
import Footer from "../../components/layouts/HomeLayout/Footer";
import { useDispatch, useSelector } from "react-redux";
import Publicacion1 from "../../assets/img/Diseno-sin-titulo-1-768x585.jpg.png";
import Publicacion2 from "../../assets/img/Portadas-Blog-GAMA-1-2-768x576.png.png";
import Publicacion3 from "../../assets/img/Portadas-Blog-GAMA-1-3-768x576.png.png";
import Logo from "../../assets/img/logo-mobility.png";

import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { Link } from "react-router-dom";
import "./index.less";
import { useMediaPredicate } from "react-media-hook";
import MobilityBanner from "../../assets/img/mobilitybanner.png";
import { useScroll } from "../../utils/useScroll";
import MobiliySvg1 from "../../assets/img/rent a car.svg";
import MobiliySvg2 from "../../assets/img/leasing.svg";
import MobiliySvg3 from "../../assets/img/renting.svg";
import MobiliySvg4 from "../../assets/img/usados.svg";
import icon1 from "../../assets/img/1.png";
import icon2 from "../../assets/img/2.png";
import icon3 from "../../assets/img/3.png";
import icon4 from "../../assets/img/4.png";
import GoToTop from "../../components/GoToTop";
import siniestroLogo from "../../assets/img/Ico_btn_portalsiniestro.svg";
import portalProveedoresLogo from "../../assets/img/Ico_btn_portal_proveedores.svg";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import {
  getGamaMobilityBlogContent,
  getGamaMobilityHomeContent,
} from "../../store/strapi/thunk";
import {
  getFooterContent,
  getGamaMobilityBlogHeader,
  getGamaMobilityBlogSections,
  getHomeHeaderContent,
  getHomeSectionsContent,
  getNavbarContent,
  getStatus,
} from "../../store/strapi";
import { STATUS } from "../../utils/status";
import LoadingStrapi from "../LoadingStrapi";

function Mobility() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const dispatch = useDispatch();
  const usadosStatus = useSelector(getStatus);
  const [loading, setLoading] = useState(true);

  const [visible, setVisible] = useState(false);
  const { Text } = Typography;
  const onClose = () => {
    setVisible(!visible);
  };
  const cleanModal = () => {
    document.getElementById("checkbox4").click();
    onClose();
  };
  const [isHome, setIsHome] = useState();

  useEffect(() => {
    if (window.location.pathname === "/mobility") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    dispatch(getGamaMobilityHomeContent());
  }, []);

  const mobilityHeader = useSelector(getNavbarContent);
  const mobilityNavBar = useSelector(getHomeHeaderContent);
  const mobilitySections = useSelector(getHomeSectionsContent);

  const ourServices = [
    {
      title: "Gama Leasing",
      description:
        "Arriendo de flota de vehículos para empresas por periodos de mediano a largo plazo con equipamiento para cada rubro, según las necesidades de su empresa",
      link: "https://gamaleasing.cl/",
      icon: MobiliySvg1,
    },
    {
      title: "Tripp",
      description:
        "Es una suscripción de arriendo por un auto, por periodos que te acomoden y puedes escoger el kilometraje a tu medida. Por el pago de una cuota mensual tienes todo incluido, así solo te preocupas del combustible y los peajes.",
      link: "https://www.tripp.cl/",
      icon: MobiliySvg2,
    },
    {
      title: "Gama Rent a car",
      description:
        "Arriendo de autos para personas y empresas por periodos determinados por el cliente, con una gama amplia desde autos categoría Economy a automóviles Premium.",
      link: "https://www.gamarent.cl/",
      icon: MobiliySvg3,
    },
    {
      title: "Gama Usados",
      description:
        "Venta de autos usados para particulares con los estándares de garantía y calidad de Gama.",
      link: "https://gamaleasing.cl/usados/",
      icon: MobiliySvg4,
    },
  ];

  const ourBenefits = [
    {
      title: "Rapidez y calidad de servicio",
      description:
        "Entregamos un servicio de calidad, enfocado en proporcionar soluciones rápidas a las necesidades de Movilidad de nuestros clientes.​",
      icon: icon1,
    },
    {
      title: "Satisfacción de clientes y proveedores",
      description:
        "Aseguramos la satisfacción de nuestros clientes, tanto durante la entrega de nuestros servicios como durante el proceso de post venta.",
      icon: icon2,
    },
    {
      title: "Eficiencia y continuidad operacional",
      description:
        "Aseguramos la continuidad operacional de tu flota y brindamos un servicio eficaz, 100% Movilidad a todos nuestros clientes, para que tú o tu empresa siempre estén en movimiento.",
      icon: icon3,
    },
    {
      title: "Soluciones de movilidad flexibles y personalizadas",
      description:
        "Contamos con soluciones hechas a medida para satisfacer cada uno de los requerimientos y necesidades de nuestros clientes, ya sea en arriendo de flotas para empresas o de autos para particulares.",
      icon: icon4,
    },
  ];

  const CarouselImages = [
    {
      imagen: Publicacion1,
      title: "¿Cómo planear una ruta de transporte para mi empresa?",
      description:
        "Para el rubro de transporte de bienes, productos o servicios, diseñar una buena ruta comercial es la clave del éxito.…",
    },
    {
      imagen: Publicacion2,
      title:
        "Tecnologías del futuro, hoy: Todo lo que necesitas saber para tu compañía",
      description:
        "Para todas las industrias, rubros y empresas, los avances en tecnología son cada vez más grandes y beneficiosos. Si nos…",
    },
    {
      imagen: Publicacion3,
      title:
        "Beneficios de transformarte en un especialista sobre los autos eléctricos",
      description:
        "De los diversos temas e innovaciones que conciernen al mundo automotriz en su extensa gama, existe una tendencia que está…",
    },
    {
      imagen: Publicacion1,
      title: "¿Cómo planear una ruta de transporte para mi empresa?",
      description:
        "Para el rubro de transporte de bienes, productos o servicios, diseñar una buena ruta comercial es la clave del éxito.…",
    },
    {
      imagen: Publicacion2,
      title:
        "Tecnologías del futuro, hoy: Todo lo que necesitas saber para tu compañía",
      description:
        "Para todas las industrias, rubros y empresas, los avances en tecnología son cada vez más grandes y beneficiosos. Si nos…",
    },
    {
      imagen: Publicacion3,
      title:
        "Beneficios de transformarte en un especialista sobre los autos eléctricos",
      description:
        "De los diversos temas e innovaciones que conciernen al mundo automotriz en su extensa gama, existe una tendencia que está…",
    },
  ];

  const { scrollDirection } = useScroll();

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    if (usadosStatus === STATUS.SUCCESS) {
      document.body.style.overflowY = "scroll";
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [usadosStatus]);

  const options = {
    rewind: true,
    type: "loop",
    // autoplay: true,
    perPage: biggerThan900 ? 3 : 1,
    perMove: 1,
  };

  useEffect(() => {
    dispatch(getGamaMobilityBlogContent());
  }, []);

  const blogSections = useSelector(getGamaMobilityBlogSections);

  return (
    <div className="first-container">
      <GoToTop />

      {loading && (
        <div style={{ zIndex: "9999", position: "relative", opacity: 1 }}>
          <LoadingStrapi />
        </div>
      )}

      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `100vh`,
          }}
        >
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div
              style={{
                marginRight: biggerThan900 ? "" : "5px",
                width: biggerThan900 ? "270px" : "100px",
                paddingTop: "10px",
                paddingBottom: "10px",
                backgroundColor: "white",
                position: "fixed",
                zIndex: "999",
                marginTop: biggerThan900 ? "200px" : "120px",
                borderTopLeftRadius: biggerThan900 ? "40px" : "20px",
                borderBottomLeftRadius: biggerThan900 ? "40px" : "20px",
                borderTopRightRadius: biggerThan900 ? 0 : "20px",
                borderBottomRightRadius: biggerThan900 ? 0 : "20px",
                cursor: "pointer",
                border: "solid .4px #c3c3c3",
              }}
            >
              <a href="https://gamaleasing.cl/mantenimiento">
                <Row align="middle" justify="center">
                  <Col span={biggerThan900 ? 4 : 10}>
                    <img
                      alt="Declaracion de Robo y siniestros"
                      src={siniestroLogo}
                      style={{
                        fontSize: "40px",
                        color: "#FF3200",
                      }}
                    />
                  </Col>

                  <Col
                    span={biggerThan900 ? 15 : 18}
                    style={{
                      color: "#FF3200",
                      fontSize: biggerThan900 ? "17px" : "10px",
                      margin: 0,
                      fontWeight: "700",
                      lineHeight: "20px",
                      marginLeft: "5px",
                      textAlign: biggerThan900 ? "" : "center",
                    }}
                  >
                    Declaracion de Robo y siniestros
                  </Col>
                </Row>
              </a>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div
              style={{
                marginRight: biggerThan900 ? "" : "5px",
                width: biggerThan900 ? "270px" : "100px",
                paddingTop: "10px",
                paddingBottom: "10px",
                backgroundColor: "white",
                position: "fixed",
                zIndex: "999",
                marginTop: biggerThan900 ? "280px" : "250px",
                borderTopLeftRadius: biggerThan900 ? "40px" : "20px",
                borderBottomLeftRadius: biggerThan900 ? "40px" : "20px",
                borderTopRightRadius: biggerThan900 ? 0 : "20px",
                borderBottomRightRadius: biggerThan900 ? 0 : "20px",
                cursor: "pointer",
                border: "solid .4px #c3c3c3",
              }}
            >
              <a href="https://am-external.gamamobility.cl/">
                <Row align="middle" justify="center">
                  <Col span={biggerThan900 ? 4 : 10}>
                    <img
                      alt="Portal de servicios y Proveedores"
                      src={portalProveedoresLogo}
                      style={{
                        fontSize: "40px",
                        color: "#FF3200",
                        width: "40px",
                      }}
                    />
                  </Col>

                  <Col
                    span={biggerThan900 ? 15 : 18}
                    style={{
                      color: "#FF3200",
                      fontSize: biggerThan900 ? "17px" : "10px",
                      margin: 0,
                      fontWeight: "700",
                      lineHeight: "20px",
                      marginLeft: "5px",
                      textAlign: biggerThan900 ? "" : "center",
                    }}
                  >
                    Portal de servicios y Proveedores
                  </Col>
                </Row>
              </a>
            </div>
          </div>
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                mobilityNavBar?.length === 0 || mobilityNavBar === null
                  ? MobilityBanner
                  : mobilityNavBar.BannerImage[0].url
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "/"
                            ? "#FF3200"
                            : "white",
                      }}
                      to={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "/"
                          : mobilityHeader?.TopLinks[0].Hreference
                      }
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "GAMA MOBILITY"
                        : mobilityHeader?.TopLinks[0].label}
                    </Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      href={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "https://gamaleasing.cl/"
                          : mobilityHeader?.TopLinks[1].Hreference
                      }
                      rel="noreferrer"
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "GAMA LEASING"
                        : mobilityHeader?.TopLinks[1].label}
                    </a>
                  </li>

                  <li>
                    <a
                      href={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "https://gamaleasing.cl/"
                          : mobilityHeader?.TopLinks[2].Hreference
                      }
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "RENT A CAR"
                        : mobilityHeader?.TopLinks[2].label}
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "https://www.tripp.cl/"
                          : mobilityHeader?.TopLinks[3].Hreference
                      }
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "TRIPP"
                        : mobilityHeader?.TopLinks[3].label}
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={
                        mobilityHeader?.length === 0 || mobilityHeader === null
                          ? "https://www.tripp.cl/"
                          : mobilityHeader?.TopLinks[4].Hreference
                      }
                      rel="noreferrer"
                    >
                      {mobilityHeader?.length === 0 || mobilityHeader === null
                        ? "GAMA USADOS"
                        : mobilityHeader?.TopLinks[4].label}
                    </a>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/"}>
                      <Image
                        preview={false}
                        src={
                          mobilityHeader?.length === 0 ||
                          mobilityHeader === null
                            ? Logo
                            : mobilityHeader?.Logo?.url
                        }
                        width={biggerThan900 ? 170 : 130}
                      />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    {biggerThan900 ? (
                      <div
                        className="nav-container-buttons"
                        style={{
                          transition: "all ease-out 1s;",
                          opacity: `${visible ? "0" : "1"}`,
                          pointerEvents: `${visible ? "none" : "auto"}`,
                        }}
                      >
                        <Link
                          to={
                            mobilityHeader?.length === 0 ||
                            mobilityHeader === null
                              ? "/contacto-mobility"
                              : mobilityHeader?.NavButton[0]?.Hreference
                          }
                        >
                          <Button
                            onClick={() => {}}
                            className="gama-outline talk-button"
                          >
                            {mobilityHeader?.length === 0 ||
                            mobilityHeader === null
                              ? "Conversemos"
                              : mobilityHeader?.NavButton[0]?.Label}
                          </Button>
                        </Link>
                      </div>
                    ) : null}
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a
                                target="_blank"
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "https://gamaleasing.cl/sucursales"
                                    : mobilityHeader?.NavLinks[0].Hreference
                                }
                                rel="noreferrer"
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Sucursales"
                                  : mobilityHeader?.NavLinks[0].label}
                              </a>
                              <a
                                smooth
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#servicios"
                                    : mobilityHeader?.NavLinks[1].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Nuestros servicios"
                                  : mobilityHeader?.NavLinks[1].label}
                              </a>
                              <a
                                smooth
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#nosotros"
                                    : mobilityHeader?.NavLinks[2].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Quienes Somos"
                                  : mobilityHeader?.NavLinks[2].label}
                              </a>
                              <a
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#beneficios"
                                    : mobilityHeader?.NavLinks[3].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Beneficios"
                                  : mobilityHeader?.NavLinks[3].label}
                              </a>
                              <a
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#publicaciones"
                                    : mobilityHeader?.NavLinks[4].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Publicaciones"
                                  : mobilityHeader?.NavLinks[4].label}
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                target="_blank"
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "https://gamaleasing.cl/sucursales"
                                    : mobilityHeader?.NavLinks[0].Hreference
                                }
                                rel="noreferrer"
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Sucursales"
                                  : mobilityHeader?.NavLinks[0].label}
                              </a>
                              <a
                                smooth
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#servicios"
                                    : mobilityHeader?.NavLinks[1].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Nuestros servicios"
                                  : mobilityHeader?.NavLinks[1].label}
                              </a>
                              <a
                                smooth
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#nosotros"
                                    : mobilityHeader?.NavLinks[2].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Quienes Somos"
                                  : mobilityHeader?.NavLinks[2].label}
                              </a>
                              <a
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#beneficios"
                                    : mobilityHeader?.NavLinks[3].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Beneficios"
                                  : mobilityHeader?.NavLinks[3].label}
                              </a>
                              <a
                                href={
                                  mobilityHeader?.length === 0 ||
                                  mobilityHeader === null
                                    ? "#publicaciones"
                                    : mobilityHeader?.NavLinks[4].Hreference
                                }
                              >
                                {mobilityHeader?.length === 0 ||
                                mobilityHeader === null
                                  ? "Publicaciones"
                                  : mobilityHeader?.NavLinks[4].label}
                              </a>
                            </>
                          )}
                        </div>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            id="checkbox4"
                            className="checkbox4 visuallyHidden"
                          ></input>
                          <label htmlFor="checkbox4" onClick={() => onClose()}>
                            <div className="hamburger hamburger4">
                              <span className="bar bar1"></span>
                              <span className="bar bar2"></span>
                              <span className="bar bar3"></span>
                              <span className="bar bar4"></span>
                              <span className="bar bar5"></span>
                            </div>
                          </label>

                          <div
                            className={
                              visible === true ? "modal modal-active" : "modal"
                            }
                          >
                            <div className="delimiter"></div>
                            <div className="container-modal">
                              <div
                                className="header-modal"
                                style={{ height: "55%" }}
                              >
                                <div className="nav-group">
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "https://gamaleasing.cl/sucursales"
                                        : mobilityHeader?.NavLinks[0].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Sucursales"
                                      : mobilityHeader?.NavLinks[0].label}
                                  </a>
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "#servicios"
                                        : mobilityHeader?.NavLinks[1].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Nuestros servicios"
                                      : mobilityHeader?.NavLinks[1].label}
                                  </a>
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "#nosotros"
                                        : mobilityHeader?.NavLinks[2].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Quienes Somos"
                                      : mobilityHeader?.NavLinks[2].label}
                                  </a>
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "#beneficios"
                                        : mobilityHeader?.NavLinks[3].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Beneficios"
                                      : mobilityHeader?.NavLinks[3].label}
                                  </a>
                                  <a
                                    smooth
                                    href={
                                      mobilityHeader?.length === 0 ||
                                      mobilityHeader === null
                                        ? "#publicaciones"
                                        : mobilityHeader?.NavLinks[4].Hreference
                                    }
                                    onClick={cleanModal}
                                  >
                                    {mobilityHeader?.length === 0 ||
                                    mobilityHeader === null
                                      ? "Publicaciones"
                                      : mobilityHeader?.NavLinks[4].label}
                                  </a>
                                </div>

                                <div className="button-group">
                                  <a
                                    target="_blank"
                                    href={
                                      mobilityNavBar?.length === 0 ||
                                      mobilityNavBar === null
                                        ? "/contacto-mobility"
                                        : mobilityNavBar?.Button[0]?.Hreference
                                    }
                                    rel="noreferrer"
                                  >
                                    <Button className="gama-outline talk-button">
                                      {mobilityNavBar?.length === 0 ||
                                      mobilityNavBar === null
                                        ? "Conversemos"
                                        : mobilityNavBar?.Button[0]?.Label}
                                    </Button>
                                  </a>
                                </div>
                              </div>
                              <div
                                className="footer-modal"
                                style={{
                                  height: "45%",
                                  backgroundColor: "#2D2D2D",
                                }}
                              >
                                <ul style={{ paddingBottom: "15px" }}>
                                  <li>
                                    <Link
                                      style={{
                                        color:
                                          window.location.pathname === "/"
                                            ? "#FF3200"
                                            : "white",
                                      }}
                                      to={"/"}
                                    >
                                      GAMA MOBILITY
                                    </Link>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://gamaleasing.cl/"}
                                      rel="noreferrer"
                                    >
                                      GAMA LEASING
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://www.gamarent.cl/"}
                                      rel="noreferrer"
                                    >
                                      RENT A CAR
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://www.tripp.cl/"}
                                      rel="noreferrer"
                                    >
                                      TRIPP
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={"https://gamaleasing.cl/usados"}
                                      rel="noreferrer"
                                    >
                                      GAMA USADOS
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                color: "white",
                fontSize: "48px",
                width: biggerThan900 ? "800px" : "100%",
                fontWeight: "700",
                lineHeight: "58px",
              }}
            >
              {mobilityNavBar?.length === 0 || mobilityNavBar === null
                ? "Entregamos el mejor servicio de movilidad para personas y empresas."
                : mobilityNavBar?.Title?.Title}
            </h1>
            <h3
              style={{
                textAlign: "",
                lineHeight: "32px",
                fontSize: "24px",
                fontWeight: "400",
                width: biggerThan900 ? "750px" : "100%",

                // maxWidth: biggerThan900 ? "800px" : "",
                // marginRight: biggerThan900 ? "1120px" : "",
                paddingLeft: "0",
              }}
            >
              {mobilityNavBar?.length === 0 || mobilityHeader === null
                ? "A través de procesos simples brindamos una experiencia de servicio que marca una diferencia en el mercado."
                : mobilityNavBar?.Title?.Detail}
            </h3>

            <Link
              to={
                mobilityNavBar?.length === 0 || mobilityNavBar === null
                  ? "/contacto-mobility"
                  : mobilityNavBar?.Button[0]?.Hreference
              }
            >
              <Button
                id="servicios"
                style={{
                  width: "11rem",
                  marginTop: "1.5rem",
                  backgroundColor: "#FF5F00",
                  border: "none",
                }}
              >
                {mobilityNavBar?.length === 0 || mobilityNavBar === null
                  ? "Conversemos"
                  : mobilityNavBar?.Button[0]?.Label}
              </Button>
            </Link>
          </div>
        </section>
      </Layout>
      <Row
        justify="center"
        style={{
          marginTop: biggerThan900 ? "100px" : "0px",
          marginBottom: "80px",
        }}
      >
        {biggerThan900 ? (
          <Text strong style={{ fontSize: "40px", fontWeight: "700" }}>
            {mobilitySections?.length === 0 || mobilitySections === null
              ? "Nuestros Servicios"
              : mobilitySections[0]?.Title}
          </Text>
        ) : (
          <Text
            strong
            style={{
              fontSize: "40px",
              fontWeight: "700",
              marginTop: "50px",
              lineHeight: "50px",
            }}
          >
            {mobilitySections?.length === 0 || mobilitySections === null
              ? "Nuestros Servicios"
              : mobilitySections[0]?.Title}
          </Text>
        )}
      </Row>
      {biggerThan900 ? (
        <Row id="nosotros" style={{ display: "flex" }} justify="center">
          {mobilitySections?.length === 0 || mobilitySections === null
            ? ourServices.map((item) => (
                <Row align="top" justify="center">
                  <Row
                    gutter={[16, 16]}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "200px",
                      lineHeight: "24px",
                      marginLeft: "50px",
                      marginRight: "50px",
                    }}
                  >
                    <Col>
                      <img
                        alt="services-icon"
                        src={item.icon}
                        style={{ fontSize: "50px", color: "#E06329" }}
                      />
                    </Col>
                    <Col style={{ fontSize: "25px", fontWeight: "700" }}>
                      {item.title}
                    </Col>
                    <Col
                      style={{
                        minHeight: "220px",
                        color: "#717171",
                      }}
                    >
                      {item.description}
                    </Col>
                    <Col style={{ display: "flex", align: "center" }}>
                      <a href={item.link}>
                        <Button
                          style={{
                            backgroundColor: "black",
                            borderColor: "black",
                          }}
                        >
                          Quiero saber mas
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </Row>
              ))
            : mobilitySections &&
              mobilitySections[0]?.Card?.map((item) => (
                <Row align="top" justify="center">
                  <Row
                    gutter={[16, 16]}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "200px",
                      lineHeight: "24px",
                      marginLeft: "50px",
                      marginRight: "50px",
                    }}
                  >
                    <Col>
                      <img
                        alt="services-icon"
                        src={item?.Icon?.url}
                        style={{ fontSize: "50px", color: "#E06329" }}
                      />
                    </Col>
                    <Col style={{ fontSize: "25px", fontWeight: "700" }}>
                      {item?.Title}
                    </Col>
                    <Col
                      style={{
                        minHeight: "220px",
                        color: "#717171",
                      }}
                    >
                      {item?.Paragraph}
                    </Col>
                    <Col style={{ display: "flex", align: "center" }}>
                      <a href={item?.Button?.Hreference}>
                        <Button
                          style={{
                            backgroundColor: "black",
                            borderColor: "black",
                          }}
                        >
                          {item?.Button?.Label}
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </Row>
              ))}
        </Row>
      ) : (
        <div
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            width: "90%",
          }}
          className="arrow"
        >
          <Splide
            hasTrack={false}
            options={options}
            aria-label="My Favorite Images"
          >
            <SplideTrack>
              {mobilitySections?.length === 0 || mobilitySections === null
                ? ourServices.map((vehicle) => (
                    <SplideSlide
                    // style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        id="particular"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",

                          marginLeft: "80px",
                        }}
                      >
                        <Col>
                          <GiftOutlined
                            style={{
                              fontSize: "50px",
                              color: "#E06329",
                              marginRight: "20px",
                            }}
                          />
                        </Col>
                        <Col>
                          <h5
                            style={{
                              fontSize: "20px",
                              width: "287px",
                              fontWeight: "700",
                            }}
                          >
                            {vehicle.title}
                          </h5>
                        </Col>

                        <p
                          style={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            width: "250px",
                            color: "#717171",
                            minHeight: "150px",
                            textAlign: biggerThan900 ? "center" : "",
                          }}
                        >
                          {vehicle.description}
                        </p>

                        <Col
                          style={{
                            display: "flex",
                            align: "center",
                            marginBottom: "50px",
                          }}
                        >
                          <a href={vehicle.link}>
                            <Button
                              style={{
                                backgroundColor: "black",
                                borderColor: "black",
                              }}
                            >
                              Quiero saber mas
                            </Button>
                          </a>
                        </Col>
                      </div>
                    </SplideSlide>
                  ))
                : mobilitySections &&
                  mobilitySections[0]?.Card?.map((vehicle) => (
                    <SplideSlide
                    // style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        id="particular"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",

                          marginLeft: "80px",
                        }}
                      >
                        <Col>
                          <GiftOutlined
                            style={{
                              fontSize: "50px",
                              color: "#E06329",
                              marginRight: "20px",
                            }}
                          />
                        </Col>
                        <Col>
                          <h5
                            style={{
                              fontSize: "20px",
                              width: "287px",
                              fontWeight: "700",
                            }}
                          >
                            {vehicle?.Title}
                          </h5>
                        </Col>

                        <p
                          style={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            width: "250px",
                            color: "#717171",
                            minHeight: "150px",
                            textAlign: biggerThan900 ? "center" : "",
                          }}
                        >
                          {vehicle?.Paragraph}
                        </p>

                        <Col
                          style={{
                            display: "flex",
                            align: "center",
                            marginBottom: "50px",
                          }}
                        >
                          <a href={vehicle?.link}>
                            <Button
                              style={{
                                backgroundColor: "black",
                                borderColor: "black",
                              }}
                            >
                              Quiero saber mas
                            </Button>
                          </a>
                        </Col>
                      </div>
                    </SplideSlide>
                  ))}
            </SplideTrack>
          </Splide>
        </div>
      )}
      <Divider style={{ width: "100%" }} />
      <Row justify="center">
        <Row
          id="beneficios"
          justify="center"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "50px",
            marginLeft: biggerThan900 ? "0px" : "0px",
          }}
        >
          <Col
            style={{
              fontSize: "40px",
              fontWeight: "700",
              marginBottom: "16px",
            }}
          >
            {mobilitySections?.length === 0 || mobilitySections === null
              ? "Quienes Somos"
              : mobilitySections && mobilitySections[1].Title}
          </Col>
          <Col
            style={{
              width: biggerThan900 ? "500px" : "350px",
              lineHeight: "20px",
              marginBottom: "26px",
              color: "#717171",
            }}
          >
            {mobilitySections?.length === 0 || mobilitySections === null
              ? "Gama es una Sociedad compuesta por Empresas Penta, Grupo Indumotora y Eurofrance. Nace para satisfacer la demanda del mercado en el arrendamiento de flotas de vehículos para empresas o de autos para particulares en el país, a través de los servicios de Gama Leasing, Tripp, Gama Rent a Car y Gama Usados."
              : mobilitySections && mobilitySections[1].Paragraph}
          </Col>
          <Row
            justify="center"
            align="middle"
            style={{
              display: biggerThan900 ? "flex" : "flex",
              flexDirection: biggerThan900 ? "row" : "column",
              justifyContent: biggerThan900 ? "0px" : "space-between",
            }}
          >
            <img
              alt="first-step-icon"
              style={{
                width: "102px",
                height: "50px",
                marginRight: "15px",
                marginBottom: biggerThan900 ? "0px" : "15px",
              }}
              src={
                mobilitySections?.length === 0 || mobilitySections === null
                  ? Logo2
                  : mobilitySections && mobilitySections[1]?.Card[0].Icon?.url
              }
            />
            <img
              alt="second-step-icon"
              style={{
                width: "165px",
                height: "18px",
                marginRight: "15px",
                marginBottom: biggerThan900 ? "0px" : "15px",
              }}
              src={
                mobilitySections?.length === 0 || mobilitySections === null
                  ? Logo1
                  : mobilitySections && mobilitySections[1]?.Card[1].Icon?.url
              }
            />
            <img
              alt="third-step-icon"
              style={{
                width: "158px",
                height: "43px",
                marginRight: "15px",
                marginBottom: biggerThan900 ? "0px" : "15px",
              }}
              src={
                mobilitySections?.length === 0 || mobilitySections === null
                  ? Logo3
                  : mobilitySections && mobilitySections[1]?.Card[2].Icon?.url
              }
            />
          </Row>
          <Button
            style={{
              backgroundColor: "black",
              borderColor: "black",
              width: "343px",
              color: "white",
              marginTop: biggerThan900 ? "60px" : "30px",
            }}
          >
            <Link to="/mobility-nosotros" style={{ color: "white" }}>
              Conoce mas sobre nosotros
            </Link>
          </Button>
        </Row>
        <Row>
          <img
            alt="vehicle-mainicon-icon"
            style={{
              marginTop: "80px",
              marginLeft: biggerThan900 ? "26px" : "0px",
              width: "auto",
              height: biggerThan900 ? "250px" : "190px",
            }}
            src={
              mobilitySections?.length === 0 || mobilitySections === null
                ? Camioneta
                : mobilitySections && mobilitySections[1]?.Image?.url
            }
          />
        </Row>
        <Divider
          style={{
            width: "80%",
            marginTop: "50px",
          }}
        />
        <Row
          justify="center"
          style={{
            width: "70%",
            marginTop: biggerThan900 ? "80px" : "60px",
            marginBottom: biggerThan900 ? "70px" : "10px",
          }}
        >
          <Text
            strong
            style={{ fontSize: "40px", fontWeight: "700", lineHeight: "50px" }}
          >
            {mobilitySections?.length === 0 || mobilitySections === null
              ? "Beneficios"
              : mobilitySections && mobilitySections[2].Title}
          </Text>
        </Row>
        {biggerThan900 ? (
          <Row style={{ display: "flex" }} justify="center">
            {mobilitySections?.length === 0 || mobilitySections === null
              ? ourBenefits.map((item) => (
                  <Row align="top" justify="center">
                    <Row
                      gutter={[16, 16]}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "230px",
                        lineHeight: "24px",
                        marginLeft: "50px",
                        marginRight: "50px",
                      }}
                    >
                      <Col>
                        <img
                          alt="item-icon-benefits"
                          src={item.icon}
                          style={{
                            fontSize: "50px",
                            color: "#E06329",
                            maxHeight: "50px",
                            maxWidth: "50px",
                          }}
                        />
                      </Col>
                      <Col
                        style={{
                          minHeight: "80px",
                          fontSize: "25px",
                          fontWeight: "700",
                        }}
                      >
                        {item.title}
                      </Col>
                      <Col
                        id="publicaciones"
                        style={{ minHeight: "180px", color: "#717171" }}
                      >
                        {item.description}
                      </Col>
                    </Row>
                  </Row>
                ))
              : mobilitySections &&
                mobilitySections[2]?.Card?.map((item) => (
                  <Row align="top" justify="center">
                    <Row
                      gutter={[16, 16]}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "230px",
                        lineHeight: "24px",
                        marginLeft: "50px",
                        marginRight: "50px",
                      }}
                    >
                      <Col>
                        <img
                          alt="item-icon-benefits"
                          src={item?.Icon?.url}
                          style={{
                            fontSize: "50px",
                            color: "#E06329",
                            maxHeight: "50px",
                            maxWidth: "50px",
                          }}
                        />
                      </Col>
                      <Col
                        style={{
                          minHeight: "80px",
                          fontSize: "25px",
                          fontWeight: "700",
                        }}
                      >
                        {item?.Title}
                      </Col>
                      <Col
                        id="publicaciones"
                        style={{ minHeight: "180px", color: "#717171" }}
                      >
                        {item?.Paragraph}
                      </Col>
                    </Row>
                  </Row>
                ))}
          </Row>
        ) : (
          <div
            style={{
              marginTop: "50px",
              marginBottom: "50px",
              width: "90%",
              marginLeft: "20px",
            }}
            className="arrow"
          >
            <Splide
              hasTrack={false}
              options={options}
              aria-label="My Favorite Images"
            >
              <SplideTrack>
                {mobilitySections?.length === 0 || mobilitySections === null
                  ? ourBenefits.map((vehicle) => (
                      <SplideSlide
                        style={{ width: "50px" }}
                        // style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Row
                              align="middle"
                              style={{ marginBottom: "20px" }}
                            >
                              <Col>
                                <img
                                  alt="vehicle-icon-carrousel"
                                  src={vehicle?.Icon?.url}
                                  style={{
                                    fontSize: "50px",
                                    color: "#E06329",
                                    marginRight: "15px",
                                    marginBottom: "15px",
                                    maxHeight: "50px",
                                    maxWidth: "50px",
                                  }}
                                />
                              </Col>

                              <h5
                                style={{
                                  fontSize: "20px",
                                  width: "287px",
                                  fontWeight: "700",
                                }}
                              >
                                {vehicle.title}
                              </h5>
                            </Row>
                            <p
                              style={{
                                lineHeight: "24px",
                                fontSize: "16px",
                                width: biggerThan900 ? "223px" : "300px",
                                marginBottom: biggerThan900
                                  ? "50px!important"
                                  : "45px!important",
                                color: "#717171",
                              }}
                            >
                              {vehicle.description}
                            </p>
                          </div>
                        </div>
                      </SplideSlide>
                    ))
                  : mobilitySections[2]?.Card?.map((vehicle) => (
                      <SplideSlide
                        style={{ width: "50px" }}
                        // style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Row
                              align="middle"
                              style={{ marginBottom: "20px" }}
                            >
                              <Col>
                                <img
                                  alt="vehicle-icon-carrousel"
                                  src={vehicle.Icon?.url}
                                  style={{
                                    fontSize: "50px",
                                    color: "#E06329",
                                    marginRight: "15px",
                                    marginBottom: "15px",
                                  }}
                                />
                              </Col>

                              <h5
                                style={{
                                  fontSize: "20px",
                                  width: "287px",
                                  fontWeight: "700",
                                }}
                              >
                                {vehicle.Title}
                              </h5>
                            </Row>
                            <p
                              style={{
                                lineHeight: "24px",
                                fontSize: "16px",
                                width: biggerThan900 ? "223px" : "300px",
                                marginBottom: biggerThan900
                                  ? "50px!important"
                                  : "45px!important",
                                color: "#717171",
                              }}
                            >
                              {vehicle.Paragraph}
                            </p>
                          </div>
                        </div>
                      </SplideSlide>
                    ))}
              </SplideTrack>
            </Splide>
          </div>
        )}
      </Row>
      <Divider />
      <div
        style={{
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Row
          justify="center"
          style={{
            width: "100%",
            marginTop: biggerThan900 ? "100px" : "50px",
            marginBottom: "80px",
          }}
        >
          <Text strong style={{ fontSize: "40px", fontWeight: "700" }}>
            {mobilitySections?.length === 0 || mobilitySections === null
              ? "Publicaciones"
              : mobilitySections && mobilitySections[3].Title}
          </Text>
        </Row>
        <Splide
          hasTrack={false}
          options={options}
          aria-label="My Favorite Images"
        >
          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
              <span
                style={{
                  backgroundColor: "#FF3200",
                  color: "#FF5F00",
                  cursor: "pointer",
                  padding: "6px 6px 6px 6px",
                  borderRadius: "100%",
                  border: "1px solid #ff3200",
                }}
              >
                <RightOutlined />
              </span>
            </button>
            <button className="splide__arrow splide__arrow--next">
              <span
                style={{
                  backgroundColor: "#FF3200",
                  color: "#FF5F00",
                  cursor: "pointer",
                  padding: "6px 6px 6px 6px",
                  borderRadius: "100%",
                  border: "1px solid #ff3200",
                }}
              >
                <RightOutlined />
              </span>
            </button>
          </div>
          <SplideTrack>
            {blogSections?.length === 0 || blogSections === null
              ? CarouselImages.map((vehicle) => (
                  <SplideSlide
                  // style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "center",
                        // width: "50%",
                      }}
                    >
                      <img
                        style={{
                          width: "368px",
                          height: "320px",
                          borderTopLeftRadius: "50px",
                          borderTopRightRadius: "50px",
                          marginBottom: "50px",
                        }}
                        src={vehicle.imagen}
                        alt="truck"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <h5
                          style={{
                            fontSize: "20px",
                            marginBottom: "20px",
                            width: "287px",
                            fontWeight: "700",
                          }}
                        >
                          {vehicle.title}
                        </h5>
                        <p
                          style={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            width: "262px",
                            marginBottom: "50px",
                          }}
                        >
                          {vehicle.description}
                        </p>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              : blogSections &&
                blogSections?.map((vehicle) => (
                  <SplideSlide
                  // style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "center",
                        // width: "50%",
                      }}
                    >
                      <Link to={`/blog/${vehicle?.Slug}`}>
                        <img
                          style={{
                            width: "368px",
                            height: "320px",
                            borderTopLeftRadius: "50px",
                            borderTopRightRadius: "50px",
                            marginBottom: "50px",
                            cursor: "pointer",
                          }}
                          src={vehicle.Imagen?.url}
                          alt="truck"
                        />
                      </Link>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <h5
                          style={{
                            fontSize: "20px",
                            marginBottom: "20px",
                            width: "287px",
                            fontWeight: "700",
                          }}
                        >
                          {vehicle.Titulo}
                        </h5>
                        <p
                          style={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            width: "272px",
                            marginBottom: "50px",
                          }}
                        >
                          {vehicle?.Contenido?.find(
                            (item) => item.type === "paragraph"
                          )?.children?.[0]?.text?.slice(0, 150) + "..." ||
                            "Información no disponible."}
                        </p>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
          </SplideTrack>
        </Splide>
      </div>
      <Footer logo={Logo} />
    </div>
  );
}

export default Mobility;
